import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from 'gatsby';
import { space } from '@entur/tokens';
import { ImageDisplay } from '~/components/ImageDisplay';
import PageHeader from '~/components/PageHeader';
export const query = graphql`
query animationQuery {
  files: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "videos/en---tur"}, extension: {eq: "mp4"}}) {
    videos: nodes {
      name
      publicURL
    }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <video controls style={{
      width: "100%",
      marginBottom: space.extraLarge
    }} controlsList="nofullscreen nodownload noremoteplayback noplaybackrate" disablePictureInPicture>
    <source src={props.data.files.videos.find(video => video.name === "EN---TUR_animasjon-1").publicURL} type="video/mp4" />
    </video> 
    <video controls style={{
      width: "100%"
    }} controlsList="nofullscreen nodownload noremoteplayback noplaybackrate" disablePictureInPicture>
    <source src={props.data.files.videos.find(video => video.name === "EN---TUR_animasjon-2").publicURL} type="video/mp4" />
    </video> 
    <h2 {...{
      "id": "animert-illustrasjoner"
    }}>{`Animert illustrasjoner`}</h2>
    <p>{`Vi har også animasjoner for mange av illustrasjonen våre, og jobber med å utvide animasjonsbiblioteket. Disse animasjonene kan bruke på alle digitale flater. Animasjoner av illustrasjoner kan lastes ned bak innlogging på `}<a parentName="p" {...{
        "href": "https://drive.google.com/drive/folders/151XETkW3uniEIEEb0fbn2U2XipRKDNxb?usp=drive_link"
      }}>{`Google Drive`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      